const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next')
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge({}, config, {
  /**
   * common configuration here
   */
  siteId: '5f7c76d5b57d98053e7115b9',
  disabledComponents: ['wButtonLike'],
  metadatas: {
    name: 'Testat - open',
    colorPrimary: '#e84b23'
  },
  analytics: {
    mixpanel: '7aef90a11d8c0f2dd9829450d33cf521',
    ga: 'UA-181785380-1'
  },
  filterVisibleFilters({ page, locale }) {
    return (filter) => page === 'podcasts' || page === 'filters'
  },
  pages: (pager) => {
    return pager
      .remove('radios')
      .remove('podcasts')
      .update('default', (page) => {
        page.entry.title = 'Radio'
        page.entry.visibleInMenu = true
        page.setOptions({
          payload: {
            is: 'live'
          }
        })

        return page
      })
      
      .add({
        title: 'Podcasts',
        slug: 'podcasts',
        icon: 'subscriptions',
        options: {
          filter: true,
          highlighted: false,
          payload: {
            is: 'podcast',
          }
        },
        router: {
          path: '/',
          mode: 'podcasts'
        }
      })
  }
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)